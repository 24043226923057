<template>
  <div class="stock-consumo mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Stock de consumos
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="4" sm="12">
                <v-text-field
                  v-model="date_ini"
                  outlined
                  rounded
                  dense
                  label="Fecha inicial"
                  append-icon="mdi-calendar"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" sm="12">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      outlined
                      rounded
                      dense
                      label="Fecha final"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  :headers="headers"
                  :footer-props="{
                    itemsPerPageText: 'Productos',
                    itemsPerPageOptions: [10, 25, 50, -1],
                  }"
                  :options="{
                    itemsPerPage: -1,
                  }"
                  :loading="loading_t"
                  :search="search"
                  :items="lista"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-row>
                        <v-col cols="4" class="px-0">
                          <v-text-field
                            outlined
                            rounded
                            dense
                            append-icon="mdi-magnify"
                            label="Buscar"
                            v-model="search"
                            class="pl-0"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-btn color="black" dark @click="download()">
                            <v-icon>mdi-download</v-icon>
                            Descargar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.costo_p="{ item }">
                    {{ item.costo_p | currency }}
                  </template>
                  <template v-slot:item.costo_proyectado="{ item }">
                    {{ item.costo_proyectado | currency }}
                  </template>
                  <template v-slot:item.venta_s="{ item }">
                    {{ item.venta_s | currency }}
                  </template>
                  <template v-slot:item.venta_proyectada="{ item }">
                    {{ item.venta_proyectada | currency }}
                  </template>
                  <template v-slot:item.utilidad="{ item }">
                    {{ item.utilidad | utility }}
                  </template>
                  <template v-slot:body.append>
                    <tr>
                      <th colspan="3">Totales</th>
                      <th>{{ totalizacion.inicial }}</th>
                      <th>{{ totalizacion.entradas }}</th>
                      <th>{{ totalizacion.salidas }}</th>
                      <th colspan="2">{{ totalizacion.final }}</th>
                      <th colspan="2">{{ totalizacion.costo | currency }}</th>
                      <th colspan="3">{{ totalizacion.venta | currency }}</th>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
import XLSX from "xlsx";
export default {
  name: "stockConsumos",
  data: () => ({
    loading_t: false,
    menu: false,
    date: moment().format("YYYY-MM-DD"),
    date_ini: moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    search: "",
    headers: [
      {
        text: "Cod sis",
        value: "sistema",
        align: "start",
        sortable: false,
      },
      {
        text: "Codigo",
        value: "codigo",
        sortable: false,
      },
      {
        text: "Producto",
        value: "producto",
      },
      {
        text: "Stock ini",
        value: "inicial",
      },
      {
        text: "Entradas",
        value: "entradas",
      },
      {
        text: "Salidas",
        value: "salidas",
      },
      {
        text: "Stock fin",
        value: "final",
      },
      {
        text: "Costo Uni promedio",
        value: "costo_p",
        sortable: false,
      },
      {
        text: "Costo total proyectado",
        value: "costo_proyectado",
        sortable: false,
      },
      {
        text: "Venta Uni estandar",
        value: "venta_s",
        sortable: false,
      },
      {
        text: "Venta total proyectada",
        value: "venta_proyectada",
        sortable: false,
      },
      {
        text: "Utilidad",
        value: "utilidad",
      },
    ],
    productos: [],
  }),
  methods: {
    loadStock() {
      this.loading_t = true;
      const body = {
        route: "/stock_productos",
        params: {
          stock: 2,
          date: {
            inicio: this.date_ini,
            fin: this.date,
          },
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.productos = response.data.data;
          }
        })
        .finally(() => (this.loading_t = false));
    },
    download() {
      const name = "Stock-consumos";
      let data = this.lista.map((consumo) => {
        return {
          Columna_A: consumo.sistema,
          Columna_B: consumo.codigo,
          Columna_C: consumo.producto,
          Columna_D: consumo.inicial,
          Columna_E: consumo.entradas,
          Columna_F: consumo.salidas,
          Columna_G: consumo.final,
          Columna_H: consumo.costo_p,
          Columna_I: consumo.costo_proyectado,
          Columna_J: consumo.venta_s,
          Columna_K: consumo.venta_proyectada,
          Columna_L: consumo.utilidad,
        };
      });
      data.unshift(
        {
          Columna_A: "Fecha inicial",
          Columna_B: moment(this.date_ini).format('ll'),
          Columna_C: "",
          Columna_D: "Fecha final",
          Columna_E: moment(this.date).format('ll'),
          Columna_F: "",
          Columna_G: "",
          Columna_H: "",
          Columna_I: "",
          Columna_J: "",
          Columna_K: "",
          Columna_L: "",
        },
        {
          Columna_A: "Cod sis",
          Columna_B: "Codigo",
          Columna_C: "Producto",
          Columna_D: "Stock ini",
          Columna_E: "Entradas",
          Columna_F: "Salidas",
          Columna_G: "Stock fin",
          Columna_H: "Costo uni promedio",
          Columna_I: "Costo total proyectado",
          Columna_J: "Venta uni estandar",
          Columna_K: "Venta total proyectada",
          Columna_L: "Utilidad",
        }
      );
      data.push({
        Columna_A: "Totales",
        Columna_B: "",
        Columna_C: "",
        Columna_D: this.totalizacion.inicial,
        Columna_E: this.totalizacion.entradas,
        Columna_F: this.totalizacion.salidas,
        Columna_G: this.totalizacion.final,
        Columna_H: "",
        Columna_I: this.totalizacion.costo,
        Columna_J: "",
        Columna_K: this.totalizacion.venta,
        Columna_L: "",
      });
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = name;
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
  },
  created() {
    this.loadStock();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista() {
      return this.productos.map((p) => {
        const entradas = p.entradas + p.m_entradas;
        const salidas = p.salidas + p.m_salidas;
        const final = entradas + p.inicial_stock - salidas;
        const total_c = p.precio_compra + p.compra * p.m_entradas;
        const costo_p = isNaN(total_c / entradas)
          ? p.compra
          : total_c / entradas;
        const costo_proyectado = costo_p * final;
        const venta_proyectada = p.venta * final;
        const utilidad = ((p.venta - costo_p) / p.venta) * 100;

        return {
          sistema: p.sistema,
          codigo: p.codigo,
          producto: p.producto,
          inicial: p.inicial_stock,
          entradas,
          salidas,
          final,
          costo_p,
          costo_proyectado,
          venta_s: p.venta,
          venta_proyectada,
          utilidad: isNaN(utilidad) ? 0 : !isFinite(utilidad) ? 0 : utilidad,
        };
      });
    },
    totalizacion() {
      return {
        inicial: this.lista.reduce((index, item) => {
          return index + parseFloat(item.inicial);
        }, 0),
        entradas: this.lista.reduce((index, item) => {
          return index + parseFloat(item.entradas);
        }, 0),
        salidas: this.lista.reduce((index, item) => {
          return index + parseFloat(item.salidas);
        }, 0),
        final: this.lista.reduce((index, item) => {
          return index + parseFloat(item.final);
        }, 0),
        costo: this.lista.reduce((index, item) => {
          return index + parseFloat(item.costo_proyectado);
        }, 0),
        venta: this.lista.reduce((index, item) => {
          return index + parseFloat(item.venta_proyectada);
        }, 0),
      };
    },
  },
  watch: {
    date(val) {
      this.date_ini = moment(val)
        .startOf("month")
        .format("YYYY-MM-DD");
      this.loadStock();
      return this.date;
    },
  },
};
</script>
